
define('tmpl!modules/web/templates/questions/releases', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<ul>\n  <li class="highlights" data-highlighted="1_release">\n  A package on NPM will often have more than one release. There are 27,939 packages (29.5%) that only have <b>one release</b> (that first published package).\n  </li>\n\n  <li class="highlights" data-highlighted="1_release,2-3_releases">\n    The median value is 3 releases: 50% of packages on NPM have <b>3 releases or more</b>, while the othe 50% have less than 3.\n  </li>\n\n  <li data-highlighted="">\n    The higher a package’s major version is, the more likely it is to have more releases: Packages whose major version is greater than 1.* actually have a higher median value of 7, and a mean of 13.\n  </li>\n\n  <li data-highlighted="">\n    The package with the most versions is <code>apostrophe</code> with 433 releases! It\'s current version number is 0.5.197\n  </li>\n</ul>';

}
return __p
};});

