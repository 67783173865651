
define('tmpl!modules/web/templates/vis-topbar-controls', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div>\n  ';
 data.order[breakdown].forEach(function(category, idx) { ;
__p += '\n    <div class="category" style="left: ' +
((__t = ( dims.grids[idx].offset + 5 )) == null ? '' : __t) +
'px">\n      <h4>' +
((__t = ( data.names[breakdown][idx] )) == null ? '' : __t) +
'</h4>\n      <div class=\'datapoint\'>\n        ';
 if (dims.grids[idx].cols < 2) { ;
__p += '\n          ' +
((__t = ( d3.format('%0')(data.dimensions[breakdown][category]/data.total) )) == null ? '' : __t) +
'\n        ';
 } else { ;
__p += '\n          ' +
((__t = ( d3.format('0,')(data.dimensions[breakdown][category]) )) == null ? '' : __t) +
' (' +
((__t = ( d3.format('%0')(data.dimensions[breakdown][category]/data.total) )) == null ? '' : __t) +
')\n        ';
 } ;
__p += '\n      </div>\n    </div>\n  ';
 }); ;
__p += '\n</div>';

}
return __p
};});

