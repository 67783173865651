
define('tmpl!modules/web/templates/questions/versions', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<ul>\n  <li class="highlights" data-highlighted="version_major_zero">\n    Regardless of the age, <b>most packages aren\'t past major version 0.*</b>. 82% of packages in every age bucket are 0.*.\n  </li>\n  <li data-highlighted="">\n    The semver specification gets applied fairly liberaly to package versioning. Some are very careful to bump minor and patch versions, while others speed along past the infamous 1.*, never to look back. Looking at the relationship between package age and its major version, there is no correlation between the two.\n  </li>\n</ul>\n';

}
return __p
};});

