
define('tmpl!modules/web/templates/questions-versions', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="category-description">\n  <p>\n    Packages on npm declare a version for their releases. The version of the package is specified by <a href="http://semver.org/">Semantic Versioning</a>. A version number is written like so: <code>major.minor.patch</code>.\n  </p>\n  <p>\n    A major version increment is a big deal in theory - packages should only increment a major version when when they introduce incompatible API changes. Most packages on npm (82%) have a major version of zero (0.*...).\n  </p>\n</div>\n\n<ul>\n  <li data-question="releases">\n    <div class="partial">releases</div>\n    <div class="full">How many <span class="link">releases</span> do packages have?</div>\n    <div class="content">\n\n    </div>\n  </li>\n  <li data-question="updated">\n    <div class="partial">updated</div>\n    <div class="full">How long has it been since packages were <span class="link">last updated</span>?</div>\n    <div class="content">\n\n    </div>\n  </li>\n</ul>';

}
return __p
};});

