
define('tmpl!modules/web/templates/questions-age', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="category-description">\n  <p>\n    Packages are being added to npm at a very fast pace. In the past year alone, 53,767 packages were added, which is 57% of all packages on npm!\n  </p>\n</div>\n\n<ul>\n  <li data-question="versions">\n    <div class="partial">versions</div>\n    <div class="full">How do <span class="link">versions</span> distribute across age?</div>\n    <div class="content"></div>\n  </li>\n\n  <li data-question="top_5_deep_dependents">\n    <div class="partial">dependents</div>\n    <div class="full">How many packages are in the top 5% of packages being <span class="link">dependent</span> upon by other packages?</div>\n    <div class="content"></div>\n  </li>\n</ul>\n';

}
return __p
};});

