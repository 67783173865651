
define('tmpl!modules/web/templates/questions-dependents', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div class="category-description">\n  <p>\n    The majority of packages on npm, 70,828 (75%), have no packages depending on them.\n  </p>\n  <p>\n    Only 1,072 (1%) of the package on npm have more than 100 other packages depending on them directly\n    or indirectly (by depending on packages that depend on them etc.)\n  </p>\n</div>\n\n<ul>\n  <li data-question="ageyears">\n    <div class="partial">age</div>\n    <div class="full">Are older packages more important? Let\'s look at their <span class="link">age</span>.</div>\n    <div class="content"></div>\n  </li>\n  <li data-question="maintainers">\n    <div class="partial">maintainers</div>\n    <div class="full">Do packages that have more dependents also have more <span class="link">maintainers</span>?</div>\n    <div class="content"></div>\n  </li>\n</ul>';

}
return __p
};});

