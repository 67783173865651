
define('tmpl!modules/web/templates/vis', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<section id="vis-topbar"></section>\n<section id="waffle"></section>';

}
return __p
};});

