
define('tmpl!modules/web/templates/questions/updated', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<ul>\n  <li class="highlights" data-highlighted="within_last_year">\n    Most packages on npm, 70%, have been <b>updated within the last year</b>. 21% have been\n    updated the year before that.\n  </li>\n\n  <li data-highlighted="">\n    Packages that have a higher version number tend to be updated more frequently.\n    For example, packages whose major verison is 0.* have a median of 210 days since\n    last updated. Packages whose major version is greater than 1.* have a median of 112 days\n    since last updated.\n  </li>\n\n  <li data-highlighted="">\n    Close to a third of all packages (30%) have been updated within the last 3\n    months alone.\n  </li>\n</ul>';

}
return __p
};});

