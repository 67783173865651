
define('tmpl!modules/web/templates/topbar-start', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<a class="header-image" href="/">\n  <img src="/img/logo_2.png" />\n</a>\n\n<div class="help">?</div>';

}
return __p
};});

