
define('tmpl!modules/web/templates/questions/ageyears', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<ul>\n  <li class="highlights" data-highlighted="less_than_1_yr">\n    Older packages tend to have more packages depending on them. For example, only 27% of packages\n    with 100 or more dependencies are less than a year old. On the other hand, 61% of packages with zero dependencies\n    are less than a year old.\n  </li>\n</ul>';

}
return __p
};});

