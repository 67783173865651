
define('tmpl!modules/web/templates/topbar-grid', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<a class="header-image" href="/">\n  <img src="/img/logo_2.png" />\n</a>\n\n<div class="explore-by">\n  <span>BY:</span>\n  <ul class="grid-menu">\n    <li data-grid="versions" class="selected">\n      major version #\n    </li>\n\n    <li data-grid="age">\n      age\n    </li>\n\n    <li data-grid="dependents">\n      dependents\n    </li>\n  </ul>\n</div>\n\n<div class="help">?</div>';

}
return __p
};});

