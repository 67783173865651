
define('tmpl!modules/web/templates/questions-intro', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<div>\n  <div class="about">\n    <p>\n    <a href="http://npmjs.org">NPM</a> (Node Package Manager) is the default package manager for Node.js. As of September 22nd 2014, it has over 99,000 packages hosted, a number that grew by 10,000 packages in the last two months alone.\n    </p>\n    <p>\n    As the npm ecosystem grows, it is essential to understand what these packages are, how they are used, and what the node ecosystem looks\n    like as a whole.\n    </p>\n\n    <a class="start" href="#">\n      Click here to start &raquo;\n    </a>\n\n    <div class="subtext">\n      <div class="symbol"></div>\n      Each symbol represents 100 npm packages.\n    </div>\n\n\n  </div>\n</div>\n';

}
return __p
};});

