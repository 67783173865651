
define('tmpl!modules/web/templates/questions/top_5_deep_dependents', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<ul>\n  <li class="highlights" data-highlighted="top5">\n    Older packages that are in the top 5% of packages dependend upon, have slightly more dependents:\n    Packages that were created in:\n      <ol>\n        <li>the past year: 1,317 (median 20)</li>\n        <li>the year before: 1,866 (median 24)</li>\n        <li>the before that: 1,931 (median 33)</li>\n      </ol>\n      Age is very weak predictor of dependency (which makes sense - older packages have had more time to get a user base but there are enough very popular and relatively newer packages that break that trend.)\n  </li>\n</ul>';

}
return __p
};});

