
define('tmpl!modules/web/templates/questions/maintainers', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape;
with (obj) {
__p += '<ul>\n  <li class="highlights" data-highlighted="maintainers_1">\n    NPM packages are not the most collaborative of endeavours:\n    Most packages (88,142, 93%) on npm have <b>only one maintainer</b> (although contributors\n    are not accounted for.)\n  </li>\n  <li data-highlighted="">\n    Turns out, it doesn\'t take a village to raise a successful npm\n    package: There is no relationship between how many dependents a package has and how\n    many maintainers it has.\n  </li>\n</ul>';

}
return __p
};});

