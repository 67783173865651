
define('tmpl!modules/web/templates/question-breakdown', [], function() {return function(obj) {
obj || (obj = {});
var __t, __p = '', __e = _.escape, __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="question_breakdown">\n  ';
 if ((typeof obj.data !== "undefined")) {
    for(var i = 0; i < obj.data.points.length; i++) {
      var p = obj.data.points[i]; ;
__p += '\n      <div class="breakdown" data-questionSubset="' +
((__t = ( p.questionSubset )) == null ? '' : __t) +
'">\n\n        <div class="breakdown-details">\n          <div class="symboldefault" style="background-color: ' +
((__t = ( p.color )) == null ? '' : __t) +
'"></div> ' +
((__t = ( p.name )) == null ? '' : __t) +
'\n          <div class="subtext">(' +
((__t = ( p.value )) == null ? '' : __t) +
', ' +
((__t = ( p.percentage )) == null ? '' : __t) +
' of total)</div>\n        </div>\n\n        <div class="barcontainer">\n          <div class="bargraph" style="width: ' +
((__t = ( p.barwidth )) == null ? '' : __t) +
'px; background-color: ' +
((__t = ( p.color )) == null ? '' : __t) +
'"></div>\n        </div>\n\n        <div class="close subtext">\n          x\n        </div>\n      </div>\n    ';
 } ;
__p += '\n  ';
 } else { ;
__p += '\n    nopoints\n  ';
 } ;
__p += '\n</div>';

}
return __p
};});

